
.statGroup {
    margin-bottom: 30px;

    @media (min-width: 768px) {
        margin-bottom: 60px;
    }

    &__inner {
        display: flex;
        justify-content: space-evenly;
    }
    
    h3 {
        text-align: center;
        font-size: 24px;
        margin-bottom: 15px;
        color: #d3d3d3;
    }

    h4 {
        flex: 1;
        text-align: center;
    }
}

.stat {
    &__value {
        display: block;
        font-size: 30px;
        text-align: center;
    }

    &__name {
        color: #d3d3d3;
    }
}
