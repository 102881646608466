
.menu {
    border: 1px solid white;
    border-radius: 10px;
    padding: 15px;
    margin-top: 10px;
    color: white;

    .btn {
        width: auto;
    }
}
