
header {
    border-bottom: 3px solid grey;
    margin-bottom: 15px;
    padding: 1rem 0;

    .container {
        display: flex;
        align-items: center;
    }

    .button-actions {
        flex: 1 1 0%;
        display: flex;
        align-items: center;

        button {
            height: 33px;
            width: 33px;
            margin: 5px;
            appearance: none;
            background: none;
            color: white;
            padding: 2px;
            border: none;
            cursor: pointer;

            svg path {
                fill: #ba8af5;
                will-change: fill;
                transition: fill 0.2s ease-in-out;
            }

            &:hover,
            &:focus {
                svg path {
                    fill: #5c2aa1;
                }
            }
        }
    }
}

h1 {
    color: white;
    font-size: 26px;
    line-height: 1.4;
    margin-bottom: 0;
    margin-top: 0;

    @media (min-width: 768px) {
        font-size: 30px;
    }
}



.justify-end {
    justify-content: flex-end;
}
