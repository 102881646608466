
.guess {
    background-color: #3e4058;
    background-color: #2d2f45;
    color: white;
    font-weight: 600;
    border-radius: 5px;
    padding: 7px 10px;
    min-height: 38px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;

    &__country {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
        min-width: 0;
    }

    &__distance {
        margin-left: 5px;
        display: inline-block;
    }

    &__percentage {
        margin-left: 5px;
        min-width: 40px;
        text-align: right;
        display: inline-block;

        &.excellent {
            color: #4ecf1a;
        }

        &.great {
            color: #f2f51d;
        }

        &.good {
            color: #e9a11d;
        }

        &.poor {
            color: #fc4242;
        }
    }
}
