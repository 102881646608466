
.flag-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    margin-bottom: 40px;
}

.game-ui {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    &__button {
        border: none;
        appearance: none;
        padding: 11px 20px;
        background-color: #23494e;
        color: #fff;
        font-weight: 600;
        font-size: 19px;
        line-height: 1.3;
        cursor: pointer;
        margin-bottom: 25px;
        min-width: 9rem;

        &.is-selected {
            color: #23494e;
            background-color: transparent;
            border: 2px solid #23494e;
        }
    }
}

.seed-value {
    cursor: pointer;
}

.game-state, 
.seed-value {
    font-size: 12px;
    color: #898989;
    margin-top: 5px;
    display: inline-block;
}
